import SEO from "../components/seo"
import Layout from "../components/layout"
import React from "react"
import styled from "@emotion/styled"
import ImageGallery from '../components/image-gallery'
import Img from 'gatsby-image'
import playButtonRed from '../components/play-button-red.svg'
import textSeparator from '../images/textseparator.png'

const ACTIVITIES_PATH = {
  en: "/activities",
  es: "/actividades",
}

const MONTHS = {
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  es: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
}

const monthToText = (mm, lang) => {
  return MONTHS[lang][parseInt(mm) - 1]
}

const ActivitiesPage = (props) => {
  const { data, lang } = props.pageContext
  const byMonths = {}
  data.forEach(node => {
    console.log('node.date', node.date)
    const month = node.date.substring(7, 0) // YYYY-MM
    if (!byMonths[month]) {
      byMonths[month] = []
    }
    byMonths[month].push(node)
  })
  const sortedMonths = Object.keys(byMonths).sort().reverse()

  Object.keys(byMonths).forEach(yearMonth => {
    byMonths[yearMonth] = byMonths[yearMonth].sort((n1,n2)=> n1.date < n2.date)
  })

  const title = { es: "Actividades", en: "Activities" }[lang]
  return (
    <StyledLayout lang={lang} pathEn={ACTIVITIES_PATH.en} pathEs={ACTIVITIES_PATH.es} currentPath={ACTIVITIES_PATH[lang]}>
      <SEO title={title} />

      <h1 css={{ marginBottom: 24, textAlign: 'center' }}>{title}</h1>

      {
        sortedMonths.map(month => {
          const [yyyy, mm] = month.split('-')
          const monthName = monthToText(mm, lang)
          return (
            <MonthItemsGroup key={month}>
              <Month>{monthName} {yyyy}</Month>
              <ActivityItems>
                {
                  byMonths[month].map(node => (
                    <ActivityItem key={node.id}>
                      <ActivityTitle>
                        {
                          node.frontmatter ? node.frontmatter.title : node.title
                        }
                      </ActivityTitle>
                      <ActivityItemContent key={`itm=${node.id}`} dangerouslySetInnerHTML={{ __html: node.html ? node.html : node.content }} />
                      {
                        node.galleryImages ? (
                          <ImageGallery photos={node.galleryImages} direction={node.frontmatter.imageGalleryDirection}/>
                        ) : null
                      }
                      {
                        node.frontmatter && node.frontmatter.videos ? <Videos>
                          {
                            node.frontmatter.videos.map(video => (
                                <Video key={video.url} href={video.url} target="__blank" rel="nofollow">
                                  <PlayButton />
                                  <Img fixed={video.coverImage.childImageSharp.fixed} />
                                  <VideoTitle>
                                    {video.title}
                                  </VideoTitle>
                                </Video>
                            ))
                          }
                        </Videos> : null
                      }

                      <ActivityBottomImage>
                        <img alt="separator" src={textSeparator} />
                        </ActivityBottomImage>
                    </ActivityItem>
                  ))
                }
              </ActivityItems>
            </MonthItemsGroup>
          )
        })
      }
    </StyledLayout>
  )

}

const StyledLayout = styled(Layout)`
  p {
    margin: 0;
  }
`

const Videos = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 48px;
  justify-items: center;
`
const VideoTitle = styled.div`
  opacity: 0;
`
const Video = styled.a`
  position: relative;
  margin: 0 auto;
  &:hover {
    opacity: 0.6;
    ${VideoTitle} {
      color: white;
    }
  }
  ${VideoTitle} {
    opacity: 1;
    text-align: center;
    color: rgba(255,255,255,.9);
    top: 20px;
    position: absolute;
    width: 100%;
    font-weight: 400;
    background-color: rgba(204, 39, 39, 0.4);
    padding: 4px 0 4px 0;
  }
`
const PlayButton = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-image: url(${playButtonRed}); 
  &:hover {
    background-image: url(${playButtonRed}); 
  }
  background-repeat: no-repeat;
  background-size: 48px 48px;
  z-index: 1;
`

const Month = styled.div`
  font-weight: 800;
  font-size: 28px;
  color: rgba(0,0,0,.8);
  margin-right: 48px;
  text-align: right;
  width: 120px;
  box-sizing: border-box;
  word-wrap: normal;
`

const ActivityTitle = styled.h2`
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  color: rgba(0,0,0,.8);
  background: rgba(0,0,0,.1);
  padding: 4px 0;
  margin-bottom: 8px;
`

const MonthItemsGroup = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 750px) {
    flex-direction: column;
    ${Month} {
      width: 100%;
      text-align: left;
      margin-bottom: 8px;
    }
  }
  &:not(:first-of-type) {
    margin-top: 48px;
  }
`

const ActivityItems = styled.div`
  flex: 1;
`
const ActivityBottomImage = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    margin: 0;
  }
`

const ActivityItem = styled.div`
  &:not(:first-of-type) {
    margin-top: 48px;
  }
`
const ActivityItemContent = styled.div`
  margin-bottom: 8px;
`

export default ActivitiesPage
